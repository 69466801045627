const HEADER_HEIGHT = 72;
const FOOTER_HEIGHT = 213;
const NAVIGATOR_HEIGHT = 62;

const MOBILE_WIDTH = 767;
const TABLET_WIDTH = 1024;
const WIDTH720 = 720;
const MAX_WIDTH = 1200;

export {
  HEADER_HEIGHT,
  FOOTER_HEIGHT,
  NAVIGATOR_HEIGHT,
  MOBILE_WIDTH,
  TABLET_WIDTH,
  WIDTH720,
  MAX_WIDTH,
};
