import { isViewportTopState } from "@/atoms/viewportTopState";
import { HEADER_HEIGHT } from "@/constants/components";
import { tossColor } from "@/containers/Toss/constants/color";

import { Stack } from "@chakra-ui/react";

import {
  colors,
  HStack,
  GoiLogo,
  Spacing,
  GLOBAL_MAX_WIDTH,
  NextLink,
  customEvent,
  useResponsive,
  responsive,
  Button,
  VStack,
} from "goi_common";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";

export default function TossHeader({ onOpen }: { onOpen: () => void }) {
  const { isBiggerThanLg } = useResponsive();
  const router = useRouter();

  const isHeaderTransparentPath = router.route.includes("/introduce");

  const isHeaderContentHiddenRoute = router.route.includes("registration");

  const isViewportTop = useRecoilValue(isViewportTopState);

  const isHeaderTransparents = useMemo(() => {
    return isHeaderTransparentPath && isViewportTop;
  }, [isHeaderTransparentPath, isViewportTop]);

  return (
    <>
      <VStack
        css={responsive({
          height: {
            base: HEADER_HEIGHT,
            lg: HEADER_HEIGHT,
          },
          borderBottom: `1px solid ${
            isHeaderTransparents ? colors.gray300 : colors.gray100
          }`,
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          position: "fixed",
          width: "100%",
          top: "0",
          backgroundColor: isHeaderTransparents ? "transparent" : colors.white,
          transition: "background-color 0.1s linear",
          zIndex: "1000",
          userSelect: "none",
        })}
      >
        <Stack
          css={responsive({
            flex: 1,
            width: { base: "100%", lg: `${GLOBAL_MAX_WIDTH}px` },
            flexDirection: { base: "row", lg: "row" },
            justifyContent: { base: "space-between" },
            alignItems: { base: "", lg: "center" },
            padding: { base: "0px 20px" },
          })}
        >
          <HStack
            alignItems="center"
            justifyContent="space-between"
            gap={isBiggerThanLg ? "36px" : "20px"}
          >
            <NextLink
              id="goi-logo"
              href={"/toss/"}
              css={{ width: "fit-content" }}
              onClick={() => {
                customEvent({
                  newGtm: {
                    path: "toss",
                    trigger_and_target: "logo-uv_home",
                  },
                });
              }}
            >
              <HStack gap={isBiggerThanLg ? "12px" : "8px"} alignItems="center">
                <GoiLogo
                  color={isHeaderTransparents ? "white" : "black"}
                  size={isBiggerThanLg ? 30 : 24}
                />
                <div
                  className={
                    isHeaderTransparents
                      ? "subtitle_18_b md_subtitle_21_b white"
                      : "subtitle_18_b md_subtitle_21_b black"
                  }
                >
                  PRIME
                </div>
              </HStack>
            </NextLink>
          </HStack>
          <HStack
            alignItems="center"
            justifyContent="center"
            gap={isBiggerThanLg ? "36px" : "20px"}
          >
            {!isHeaderContentHiddenRoute && (
              <>
                <Button
                  className="subtitle_14_b"
                  css={{
                    width: "112px",
                    padding: "6px 12px",
                    backgroundColor: isHeaderTransparents
                      ? "transparent"
                      : tossColor["700"],
                    color: "white",
                    borderRadius: "5px",
                    border: "1px solid white",
                  }}
                  onClick={() => {
                    router.push("/toss/introduce/");
                  }}
                >
                  고이 소개
                </Button>
              </>
            )}
          </HStack>
        </Stack>
      </VStack>
      {isHeaderTransparentPath || <Spacing gap={HEADER_HEIGHT} />}
    </>
  );
}
