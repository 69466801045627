const NAVER_WA = "s_3d17c1f40f88";

export const naverPageView = () => {
  if (!window.wcs_add) window.wcs_add = {};
  window.wcs_add["wa"] = NAVER_WA;
  if (!window._nasa) window._nasa = {};
  if (window.wcs) {
    window.wcs.inflow();
    window.wcs_do(window._nasa);
  }
};

export const naverConversionEvent = ({
  type,
  value,
}: {
  type: "view_product" | "begin_checkout" | "purchase";
  value?: string;
}) => {
  if (window.wcs) {
    if (!window.wcs_add) window.wcs_add = {};

    window.wcs_add["wa"] = NAVER_WA;

    window.wcs.inflow("prime.goifuneral.co.kr");

    let _conv;
    if (type === "purchase" && value) {
      _conv = { type, value };
    } else {
      _conv = { type };
    }

    window.wcs.trans(_conv); // 전환이벤트 정보를 담은 object를 서버에 전송(위 item #1, #2 포함)
  }
};
