import { useActivePathCheck } from "@/hooks/useActivePathCheck";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Box,
} from "@chakra-ui/react";

import {
  customEvent,
  useResponsive,
  NextLink,
  VStack,
  ColorIcon,
  colors,
} from "goi_common";

interface MenuProps {
  isOpen: boolean;
  onClose: () => void;
}

function Menu({ isOpen, onClose }: MenuProps) {
  const { isBiggerThanMd } = useResponsive();
  const { activePath } = useActivePathCheck();

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay bg="rgba(0, 0, 0, 0.5)" />
      <DrawerContent
        width="100%!important"
        maxWidth={isBiggerThanMd ? "360px" : "100%"}
      >
        <DrawerBody p="24px">
          <VStack width="100%" alignItems="flex-end" padding={"0 0 20px"}>
            <Box
              onClick={() => {
                customEvent({
                  newGtm: {
                    path: "gnb",
                    trigger_and_target: "menu_close",
                  },
                });
                onClose();
              }}
            >
              <ColorIcon name="icon-x-mono" size={24} color={colors.gray500} />
            </Box>
          </VStack>
          <VStack gap="24px">
            <NextLink
              className={
                activePath === "홈"
                  ? "subtitle_18_b gray900"
                  : "subtitle_18_b gray500"
              }
              href="/"
              onClick={() => {
                customEvent({
                  newGtm: {
                    path: "gnb",
                    trigger_and_target: "uv_home",
                  },
                });
                onClose();
              }}
            >
              홈
            </NextLink>

            <NextLink
              className={
                activePath === "기업 고객"
                  ? "subtitle_18_b gray900"
                  : "subtitle_18_b gray500"
              }
              href="/b2b/"
              onClick={() => {
                customEvent({
                  newGtm: {
                    path: "gnb",
                    trigger_and_target: "uv_b2b",
                  },
                });
                onClose();
              }}
            >
              기업 고객
            </NextLink>
            {/* <NextLink
              className={
                activePath === "고이 소개"
                  ? "subtitle_18_b gray900"
                  : "subtitle_18_b gray500"
              }
              href="/introduce/"
              onClick={() => {
                customEvent({
                  newGtm: {
                    path: "gnb",
                    trigger_and_target: "uv_b2b",
                  },
                });
                onClose();
              }}
            >
              고이 소개
            </NextLink> */}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default Menu;
